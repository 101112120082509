<template>
  <div>
    <div class="two-col-form">
      <div class="form-group">
        <label for="selectedEvent">Select an Event:</label>
        <v-select
            id="selectedEvent"
            v-model="eventKey"
            :options="events"
            :reduce="event => event.id"
            :getOptionLabel="option => `${option.name} - ${moment(option.startDate).format('ddd MMM DD, YYYY')} - ${moment(option.endDate).format('ddd MMM DD, YYYY')}`"
            placeholder="- Select -">
          </v-select>
      </div>
      <div class="form-group">
        <span v-if="currentEvent">
          <label for="showTime">Select a Showtime:</label>
          <v-select
            id="showTime"
            v-model="showKey"
            :options="currentEvent.shows"
            :reduce="show => show.id"
            placeholder="- Select -"
            :getOptionLabel="option => `${moment(option.date).format('ddd MMM Do @ h:mma')} | $${option.ticketPrice} Per Ticket`">
          </v-select>
        </span>
      </div>
    </div>

    <div class="report-totals" v-if="showKey">
      <h4>Report Totals:</h4>
      <div class="items">
        <div><strong>Total Reservations: {{reportData.ordersCount}}</strong></div>
        <div><strong>Total Tickets: {{reportData.ticketsCount}}</strong></div>

        <div class="export" v-if="reportData.ordersCount > 0">
          <button @click="downloadAsCsv()" class="btn btn-sec">
            <i class="fa fa-file-export"></i> Export To CSV
          </button>
        </div>
      </div>
    </div>

    <div class="data-table" v-if="reportData.ordersCount > 0">
      <div class="data-table-search">
          <div class="actions">
            <span class="search-icon"><i class="fa-thin fa-search"></i></span>
            <input class="search-input" aria-label="Search Orders" type="text" :placeholder="'Search Orders'" v-model="tableSearchTerm" />
          </div>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="reportData.results"
        @on-row-click="viewPage"
        styleClass="vgt-table bookings-table"
        :sort-options="{
            enabled: true,
            initialSortBy: {field: 'createdAt', type: 'asc'}
        }"

        :search-options="{
            enabled: true,
            skipDiacritics: true,
            externalQuery: tableSearchTerm
        }"

        :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 50,
            position: 'bottom',
            dropdownAllowAll: false,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
        }"
    >

        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'id'">
              <a title="View Order"><strong>{{ props.row.id }}</strong></a>
            </span>

            <span v-else-if="props.column.field === 'userId'">
              {{ getSeasonTicketHolder(props.row.seasonTicketHolder) }}
            </span>
        </template>

      </vue-good-table>
    </div>
  </div>

</template>


<script>
import Vue from 'vue'
import { eventMixin } from '@/mixins/eventMixin';
import { Parser } from 'json2csv';
import {mapGetters} from "vuex";

export default {
  name: 'SeasonTicketReservationsByShow',

  data(){
    return {
      title: "Sales Summary by Show",
      moment: Vue.moment,
      eventKey: '',
      showKey: '',
      reportData: {results: [], originalResults: [], ordersCount: 0, ticketsCount: 0, revenue: 0},
      tableSearchTerm: '',
      columns: [
        {
          label: 'ID',
          field: 'id',
          tdClass: 'title-col',

        },
        {
          label: 'Created Date',
          field: 'createdAt'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Number of Tickets',
          field: 'ticketCount'
        },
        {
          label: 'Season Ticket Holder',
          field: 'userId'
        },
        {
          label: 'Season Ticket Number',
          field: 'stNumber'
        },
        {
          label: 'Season Ticket Package ID',
          field: 'packageId'
        }
      ]
    }
  },

  props: {
    events: Array
  },

  computed: {
    ...mapGetters({
      db: 'db',
      getCurrentPartner: 'getCurrentPartner',
      seasonTicketHolders: 'getSeasonTicketHoldersData'
    }),
  },

  mixins: [eventMixin],

  async mounted() {
    let self = this;
    self.loading = true;
    
    await self.getGridData();
    if(self.eventRef) self.tableSearchTerm = self.eventRef;
    
    self.loading = false;
  },

  methods: {
    getGridData: async function() {
      let self = this;
      await self.$store.dispatch('bindSeasonTicketHoldersData');
    },
    getSeasonTicketHolder: function(id) {
      let self = this;
      let sth = self.seasonTicketHolders.find(sth => sth.userId === id);
      return sth ? sth.name : id
    },
    downloadAsCsv() {
      console.log(this.reportData);
      let parser = new Parser({fields: [{
        label: 'ID',
        value: 'id'
      },{
        label: 'Reservation Date',
        value: 'createdAt'
      },{
        label: 'Status',
        value: 'status'
      },{
        label: 'Tickets',
        value: 'ticketCount'
      },{
        label: 'Email',
        value: 'email'
      },{
        label: 'Name',
        value: 'name'
      },{
        label: 'User ID',
        value: 'userId'
      },{
        label: 'Season Ticket Number',
        value: 'stNumber'
      },{
          label: 'Season Ticket Package ID',
          value: 'packageId'
      }]});

      for(let item of this.reportData.originalResults) {
        item.name = this.getSeasonTicketHolder(item.userId);
      }
      let csv = parser.parse(this.reportData.originalResults);
      let downloadLink = document.createElement("a");
      let blob = new Blob(["\ufeff", csv]);
      let url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "report.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    viewPage(params) {
      let self = this;
      let id = params.row.id;
      self.$router.push({name:'EditSeasonTicketReservation',params:{ref:id}})
    },
  },

  watch: {
    eventKey: function (){
      let self = this;
      self.showKey = null;
      self.reportData = {results: [], originalResults: [], ordersCount: 0, failedOrderCount: 0, ticketsCount: 0, revenue: 0};
    },
    getCurrentPartner: function(){
      let self = this;
      self.showKey = null;
      self.eventKey = null;
    },
    currentShow: async function (selectedShow) {
      let self = this;
      let reportData = self.reportData = {results: [], originalResults: [], ordersCount: 0, failedOrdersCount: 0, ticketsCount: 0, revenue: 0};

      if (!selectedShow) return;
  
      let currentShowItem = self.currentShow;
      let currentEventItem = self.currentEvent;

      let orders = await self.db
          .collection('seasonTicketReservations')
          .where('eventId', '==', self.eventKey)
          .where('showId', '==', self.showKey)
          .get();

      let results = orders.docs.map(doc => doc.data());

      for(let orderItem of results) {
        let newOrder = {
          'id': orderItem.id ? orderItem.id : null,
          'createdAt': Vue.moment(orderItem.createdAt).format("YYYY/MM/DD, HH:mma"),
          'status': orderItem.status,
          'ticketCount': orderItem.ticketCount,
          'seasonTicketHolder': orderItem.userId,
          'stNumber': orderItem.stNumber,
          'packageId': orderItem.packageId,
        };
        reportData.results.push(newOrder);

        orderItem['id'] = orderItem.id;
        orderItem['event'] = currentEventItem.name;
        orderItem['date'] = currentShowItem.date;
        reportData.originalResults.push(orderItem);

        if (newOrder.status === 'Failed') {
          reportData.failedOrdersCount++;
        }
        else {
          reportData.ordersCount++;
          reportData.ticketsCount += parseInt(orderItem.ticketCount);
          reportData.revenue += orderItem.totalOrderAmount ? orderItem.totalOrderAmount : orderItem.orderAmount;
        }
      }

      self.reportData = reportData;


    }
  },
}

</script>
