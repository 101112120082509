<template>
  <section class="page-body">

    <div class="page-title-bar sticky">
      <h1>{{ title }}</h1>

      <router-link :to="{ name: 'AddSeasonTicketReservation' }" tag="button" class="btn" title="Add Order">
        <i class="fa-thin fa-plus-circle"></i>
        Add Reservation
      </router-link>
    </div>
    
    <div class="content-panel">
      <div class="panel-body no-title">
        <div class="data-table">
          <div class="data-table-search">
            <div class="actions">
              <span class="search-icon"><i class="fa-thin fa-search"></i></span>
              <input 
                class="search-input" 
                type="text" 
                :placeholder="'Search Season Ticket Reservations'" 
                v-model="tableSearchTerm" />
            </div>
          </div>

          <vue-good-table
            v-if="!loading && gridList && gridList.length > 0"
          :columns="columns"
          :rows="gridList"
          @on-row-click="viewPage"
          styleClass="vgt-table bookings-table"
          :sort-options="{
              enabled: true,
              initialSortBy: { field: 'createdAt', type: 'desc' }
          }"

          :search-options="{
              enabled: true,
              skipDiacritics: true,
              externalQuery: tableSearchTerm
          }"

          :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 50,
              position: 'bottom',
              dropdownAllowAll: false,
              nextLabel: 'next',
              prevLabel: 'prev',
              rowsPerPageLabel: 'Rows per page',
              ofLabel: 'of',
              pageLabel: 'page', // for 'pages' mode
              allLabel: 'All',
          }">
              <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'createdAt'">
                    {{ props.row.createdAt | moment("YYYY-MM-DD") }}
                  </span>

                  <span v-else-if="props.column.field === 'updatedAt'">
                    <span v-if="props.row.status === 'cancelled'">{{ props.row.updatedAt | moment("YYYY-MM-DD") }}</span>
                    <span v-else></span>
                  </span>

                  <span v-else-if="props.column.field === 'date'">
                    {{ props.row.date | moment("ddd, MMM Do h:mma") }}
                  </span>

                  <span v-else-if="props.column.field === 'totalOrderAmount'">
                    ${{ props.row.totalOrderAmount }}
                  </span>

                  <span v-else-if="props.column.field === 'eventName'">
                    {{ getEventName(props.row.eventName) }}
                  </span>

                  <span v-else-if="props.column.field === 'showId'">
                    {{ getShowDate(props.row.eventId, props.row.showId) }}
                  </span>

                  <span v-else-if="props.column.field === 'userId'">
                    {{ getSeasonTicketHolder(props.row.userId) }}
                  </span>

                  <span v-else-if="props.column.field === 'orderNotes' && props.row.orderNotes">
                    <i class="fal fa-info-circle" v-tooltip="props.row.orderNotes"></i>
                  </span>
              </template>

          </vue-good-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SeasonTicketReservations',
  data() {
    return {
      tableSearchTerm: '',
      eventRef: this.$route.query.event,
      loading: false,
      columns: [
        {
          label: 'Created',
          field: 'createdAt'
        },
        {
          label: 'Cancelled',
          field: 'updatedAt'
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'bold'
        },
        {
          label: 'ID',
          field: 'id',
          tdClass: 'bold'
        },
        {
          label: 'Show Date',
          field: 'date',
          tdClass: 'fit-content'
        },
        {
          label: 'Event Name',
          field: 'eventName'
        },
        {
          label: 'Ticket Count',
          field: 'ticketCount'
        },
        {
          label: 'ST Holder',
          field: 'seasonTicketHolder'
        },
        {
          label: 'ST Number',
          field: 'stNumber'
        },
        {
          label: 'ST Package ID',
          field: 'packageId'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
        db : 'db',
        gridList: 'getSeasonTicketReservationsData',
        eventsList: 'getEventsData',
        seasonTicketHolders: 'getSeasonTicketHoldersData'
    }),

    title: function() {
      let self = this;
      return 'All Season Ticket Reservations'
    },
  },

  metaInfo: {
    title: 'All Season Ticket Reservations'
  },

  async mounted() {
    let self = this;
    self.loading = true;
    
    await self.getGridData();
    if(self.eventRef) self.tableSearchTerm = self.eventRef;
    
    self.loading = false;
  },

  methods: {
    getGridData: async function() {
      let self = this;

      await self.$store.dispatch('bindSeasonTicketReservationsData');
      await self.$store.dispatch('bindEventsData');
      await self.$store.dispatch('bindSeasonTicketHoldersData');

      for (let i = 0; i < self.gridList.length; i++) {
        self.gridList[i].seasonTicketHolder = self.getSeasonTicketHolder(self.gridList[i].userId);
      }
    },

    viewPage: function(params) {
      let self = this;
      let id = params.row.id;
      self.$router.push({name: 'EditSeasonTicketReservation', params:{ref:id}})
    },

    getEvent: function(id) {
      let self = this;
      let event = self.eventsList.find(event => event.id === id);
      return event
    },

    getEventName: function(id) {
      let self = this;
      let event = self.eventsList.find(event => event.id === id);
      return event ? event.name : id
    },

    getSeasonTicketHolder: function(id) {
      let self = this;
      let sth = self.seasonTicketHolders.find(sth => sth.userId === id);
      return sth ? sth.name : id
    },

    getShowDate: function(eventId, showId) {
      let self = this;
      let event = self.eventsList.find(event => event.id === eventId);
      let show;

      if (event) { 
        show = event.shows.find(show => show.id === showId) 
      }
      return show ? Vue.moment(show.date).format("ddd, MMM Do h:mma") : showId
    }
  },

  watch: {
    'db': function() {
      let self = this;
      
      self.getGridData(true);
    }
  }
}
</script>
