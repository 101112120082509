<template>
  <div class="page-body" :key="componentKey">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
      </h1>

      <div class="btn-set">
        <button class="btn" @click="saveChanges()" :disabled="saving">
          <span v-if="!saving">Save</span>
          <span v-else><i class="fal fa-spinner-third fa-spin"></i></span>
        </button>

        <router-link :to="{ name: 'SeasonTicketReservations' }" title="All Season Ticket Orders" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">
        <!-- Order Fields -->
        <ValidationObserver ref="ordersForm">
          <div class="data-form">
            <h2>Reservation Information</h2>

            <!-- Event Fields -->
            <div class="form-section" v-if="!dataRef">
              <div class="form-group">
                <label for="orderEvent">Event<span class="req">*</span></label>
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    :reduce="event => event.id"
                    v-model="dataSet.eventId"
                    :options="currentEventList"
                    @input="selectEvent"
                    placeholder="- Select an Event -"
                    label="name"
                    id="orderEvent"
                    name="orderEvent">

                    <template v-slot:option="option">
                      {{ option.name }}
                      ({{ option.startDate | moment("ddd, MMM Do, YYYY")  }} - {{option.endDate | moment("ddd, MMM Do, YYYY") }})
                    </template>

                    <template v-slot:selected-option="{ name, startDate, endDate }">
                      {{ name }}
                      ({{ startDate | moment("ddd, MMM Do, YYYY")  }} - {{ endDate | moment("ddd, MMM Do, YYYY") }})
                    </template>
                  </v-select>

                  <span class="invalid">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="two-col-form">
                <div class="form-group">
                  <label for="orderShow">Show<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      :disabled="showList.length === 0"
                      :reduce="show => show.id"
                      v-model="dataSet.showId"
                      :options="showList"
                      @input="selectShow"
                      :placeholder="showList.length === 0 ? '- Select Event -' : '- Select a Show -'"
                      label="date"
                      id="orderShow">

                      <template v-slot:option="option">
                        {{ option.date | moment("ddd, MMM Do, h:mma")  }}
                      </template>

                      <template v-slot:selected-option="{date}">
                        {{ date | moment("ddd, MMM Do, h:mma")  }}
                      </template>
                    </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="orderTickets">Number of Tickets<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      :disabled="!dataSet.showId"
                      v-model="dataSet.ticketCount"
                      :options="numberOfTickets"
                      id="orderTickets">
                    </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>

              <div class="two-col-form">
                <div class="form-group">
                  <label for="orderEvent">Season Ticket Holder<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      :reduce="user => user.userId"
                      v-model="dataSet.userId"
                      :options="seasonTicketHolders"
                      placeholder="- Select a Season Ticket Holder -"
                      label="name"
                      id="orderEvent"
                      name="orderEvent">

                      <template v-slot:option="option">
                        {{ `${option.name} (${option.email})` }}
                      </template>
                    </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>

                  <label for="notifyCustomerByEmail" v-if="!dataRef">
                    <input id="notifyCustomerByEmail" v-model="notifyCustomerByEmail" type="checkbox" />
                    Send Season Ticket Holder a Confirmation Email 
                  </label>
                </div>

                <div class="form-group">
                  <label for="orderEvent">Package<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      :disabled="!dataSet.userId || !packages || packages.length === 0"
                      :reduce="item => item.packageId"
                      v-model="dataSet.packageId"
                      :options="packages"
                      placeholder="- Select a Package -"
                      label="name"
                      id="orderPackage"
                      name="orderPackage">

                      <template v-slot:option="option">
                        {{ option.packageName }}
                      </template>

                      <template v-slot:selected-option="{packageName}">
                        {{ packageName }}
                      </template>
                    </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
            </div>

            <!-- Completed Order Summary Fields -->
            <div class="form-section" v-else>
              <div class="two-col-form">
                <div class="form-group no-fields">
                  <p>
                    <strong>Reservation Status:</strong>
                    {{ dataSet.status }}
                  </p>


                  <p v-if="dataSet.status === 'cancelled'">
                    <strong>Cancelled Date:</strong>
                    {{ dataSet.updatedAt | moment("YYYY-MM-DD") }}
                  </p>

                  <p>
                    <strong>Event:</strong>
                    {{ event.name }}
                  </p>

                  <p>
                    <strong>Season Ticket Holder:</strong>
                    <span v-if="seasonTicketHolder && seasonTicketHolder.name">{{ `${seasonTicketHolder.name} (${seasonTicketHolder.email})` }}</span>
                  </p>

                  <p>
                    <strong>Season Ticket Number:</strong>
                    {{ dataSet.stNumber }}
                  </p>
                </div>

                <div class="form-group no-fields">
                  <p>
                    <strong>Order Date / Time:</strong>
                    {{ dataSet.date | moment("ddd, MMM Do, YYYY h:mma") }}
                  </p>
                  <p>
                    <strong>Show:</strong>
                    {{ show.date | moment("ddd, MMM Do, YYYY h:mma") }}
                  </p>
                  <p>
                    <strong>Number of Tickets:</strong>
                    {{ dataSet.ticketCount }}
                  </p>
                  <p>
                    <strong>Season Ticket Package ID:</strong>
                    {{ dataSet.packageId }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">

        <!-- Custom Fulfillment Fields -->
        <div class="content-panel" v-if="dataRef">
          <div class="panel-title">
            <h3>Customer Status</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <select v-model="dataSet.customerStatus">
                <option :value="undefined" disabled>- Select -</option>
                <option value="fulfilled">Fulfilled</option>
                <optgroup label="Partially Fulfilled">
                  <option v-for="n in (dataSet.ticketCount - 1)" :key="n">{{n}}</option>
                </optgroup>
                <option value="noShow">No Show</option>
              </select>
            </div>
            <button class="btn btn-sec btn-full" v-if="dataRef" @click="notifyCustomer(getCurrentPartner, dataRef)">
              <i class="fal fa-envelope"></i>
              Email Confirmation to Customer
            </button>
          </div>
        </div>

        <!-- Order Notes -->
        <div class="content-panel">
          <div class="panel-title">
            <h3>Reservation Notes</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="orderNotes">Add any notes specific to this order:</label>
              <textarea id="orderNotes" v-model="dataSet.orderNotes"></textarea>
            </div>
          </div>
        </div>

        <!-- Delete Order -->
        <div class="link-bar" v-if="dataRef && dataSet.status !== 'cancelled'">
          <button class="btn btn-text btn-delete" @click="cancelReservation()" title="Cancel Reservation">
            <i class="fa-thin fa-trash"></i>
            Cancel Reservation
          </button>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import PaymentApi from "../../services/admin/paymentApi";
import EmailApi from "../../services/admin/emailApi";

export default {
  name: 'SeasonTicketReservation',
  data() {
    return {
      paymentApi: new PaymentApi(),
      emailApi: new EmailApi(),
      dataRef: this.$route.params.ref,
      dataSet: {
        id: '',
        status: '',
        date: '',
        email: '',
        eventId: null,
        eventName: '',
        showId: null,
        ticketCount: 2,
        userId: '',
        packageId: '',
        imageUrl: '',
        monthYear: '',
        orderNotes: ''
      },
      event: {},
      show: {
        id: null,
        ticketPrice: 0,
        taxRatePercentage: 0,
        applyServiceFeePerItem: false,
        serviceFee: 0
      },
      componentKey: 0,
      selectedShow: {},
      ticketsRemaining: null,
      eventList: [],
      showList: [],
      seasonTicketHolders: [],
      selectedSeasonTicketHolder: {},
      numberOfTickets: [1, 2, 3, 4, 5, 6],
      currentEventList: [],
      selectedEvent: {},
      newDate: new Date().toISOString(),
      loading: false,
      saving: false,
      notifyCustomerByEmail: true
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    ...mapGetters ({
      getCurrentPartner: 'getCurrentPartner'
    }),

    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Season Ticket Reservation'
      } else {
        return 'Add a Season Ticket Reservation'
      }
    },

    packages: function() {
      let self = this;
      return self.dataSet.userId ? self.seasonTicketHolders.find(user => user.userId === self.dataSet.userId).packages : [];
    },

    seasonTicketHolder: function() {
      let self = this;
      return self.seasonTicketHolders.find(sth => sth.userId === self.dataSet.userId);
    },

    ...mapGetters ([
      'db'
    ])
  },

  components: {

  },

  async mounted() {
    let self = this;

    if(self.dataRef) {
      await self.getReservation();
      await self.getEvent();
    } else {
      self.dataSet.numberOfTickets = 2;
    }

    await self.getSeasonTicketHolders();
    await self.getEventList();

  },

  methods: {
    notifyCustomer: async function (partnerId, orderId) {
      let self = this;
      await self.emailApi.sendTicketOrderEmail(partnerId, orderId);

      self.$notify({
        group: 'saved',
        title: 'Customer Successfully Notified',
        type: 'success'
      });
    },
    getReservation: async function() {
      let self = this;
      self.loading = true;

      let ordersData = await self.db.collection('seasonTicketReservations').doc(self.dataRef).get();
      self.dataSet = ordersData.data();

      self.loading = false;
    },

    getEvent: async function() {
      let self = this;
      let ref = self.dataSet.eventKey ? self.dataSet.eventKey : self.dataSet.eventId;
      let eventData = await self.db.collection('events').doc(ref).get();

      self.event = eventData.data();
      self.show = self.event.shows.find(show => show.id === self.dataSet.showId);
    },

    getEventList: async function() {
      let self = this;
      let dataSet = await self.db.collection('events').get();
      dataSet = dataSet.docs.map(doc => doc.data());

      self.eventList = dataSet.sort((a, b) => Vue.moment(a.startDate).isBefore(b.startDate) ? -1 : 1);

      self.currentEventList = self.eventList.filter(event => Vue.moment(event.endDate).isSameOrAfter(new Date(), "day"));
      self.currentEventList = self.currentEventList.filter(event => event.hasSeasonTickets);
    },

    getSeasonTicketHolders: async function() {
      let self = this;
      let dataSet = await self.db.collection('userProfiles').get();
      dataSet = dataSet.docs.map(doc => doc.data());

      self.seasonTicketHolders = dataSet;
    },

    selectEvent: function(value) {
      let self = this;
      let event = self.eventList.find(event => event.id === value);
      self.selectedEvent = event;
      self.showList = event.shows.filter(show => show.seasonTickets && show.seasonTickets > 0);
    },

    selectShow: function(value) {
      let self = this;
      let show = self.showList.find(show => show.id === value);
      self.show = show;
      self.ticketsRemaining = show.totalTickets - (show.ticketsSold || 0);
    },

    setupSavedReservation: async function(ref) {
      let self = this;
      self.dataRef = ref;
      await self.$router.push({ path: '/season-ticket-reservations/' + ref });
      await self.getReservation();
      await self.getEvent();
      await self.getEventList();
    },

    saveChanges: async function() {
      let self = this;
      let success = await self.$refs.ordersForm.validate();
      let orderId = JSON.stringify(Date.now());
      let ref = self.dataRef ? self.dataRef : orderId;

      if(success) {
        self.saving = true;
        if(self.dataRef) {
          try {
            await self.db.collection('seasonTicketReservations').doc(ref).update(self.dataSet);
            self.saving = false;
            self.$notify({
              group: 'saved',
              title: 'Reservation Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.saving = false;
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            let order = self.dataSet;
            order.id = ref;
            order.date = self.newDate;
            order.orderNotes = order.orderNotes || '';
            order.email = self.seasonTicketHolder.email || '';
            order.eventName = self.selectedEvent.name;
            order.imageUrl = self.selectedEvent.imageUrl || self.selectedEvent.comic.imageUrl || '';
            order.monthYear = Vue.moment(self.selectedShow.startDate).format("MMMM YYYY");

            order.status = 'active';

            await self.db.collection('seasonTicketReservations').doc(ref).set(order);

            if (self.notifyCustomerByEmail) {
              // await self.emailApi.sendTicketOrderEmail(self.getCurrentPartner, order.id);
            }

            self.$notify({
              group: 'saved',
              title: 'Reservation Successfully Created',
              type: 'success'
            });

            self.saving = false;
            await self.setupSavedReservation(ref);
          }
          catch (error) {
            self.saving = false;

            self.$notify({
              group: 'saved',
              title: 'Error Creating Order - ' + error,
              type: 'error'
            });
          }
        }
      }
    },

    cancelReservation: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Cancellation Confirmation',
        text: 'Are you sure you want to cancel this reservation?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.dataSet.status = 'cancelled';

              self.db.collection('seasonTicketReservations').doc(self.dataRef).update(self.dataSet)
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Reservation Cancelled',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'SeasonTicketReservations'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'SeasonTicketReservations' });
    }
  }
}
</script>
