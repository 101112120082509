<template>
  <div class="page-body">

    <div class="page-title-bar sticky">
      <h1>
        {{ title }}
      </h1>

      <div class="btn-set">

        <button class="btn" v-if="dataRef" @click="notifyCustomer(getCurrentPartner, dataRef)">Notify Customer</button>

        <button class="btn" @click="saveChanges()" :disabled="saving">
          <span v-if="!saving">Save</span>
          <span v-else><i class="fal fa-spinner-third fa-spin"></i></span>
        </button>

        <router-link :to="{ name: 'ProductOrders' }" title="All Orders" tag="button" class="btn btn-sec">
          <i class="fa-thin fa-angle-left"></i>
          Go Back
        </router-link>
      </div>
    </div>

    <p v-if="loading">Loading</p>

    <div class="body-flex" v-else>
      <div class="body-left three">

        <ValidationObserver ref="ordersForm">
          <div class="data-form">
            <h2>Product Information</h2>
            <div class="form-section" v-if="!dataRef">
              <div class="two-col-form">

                <div class="form-group">
                  <div>
                    <label for="orderProduct">Product<span class="req">*</span></label>
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-select :reduce="product => product.id"
                        v-model="dataSet.productId"
                        :options="productList"
                        @input="selectProduct"
                        label="name"
                        id="orderProduct"
                        placehoolder="- Select a Product -">
                      </v-select>

                      <span class="invalid">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                  
                  <div v-if="product.isSeasonTicketProduct" style="margin-top: 20px;">
                    <div>
                      <label for="seasonTicketNumber">Season Ticket Number</label>
                      <input type="text" id="seasonTicketNumber" v-model="dataSet.stNumber" />
                    </div>
                  </div>
                </div>


                <div class="form-group">
                  <label for="orderItems">Number of Items<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="dataSet.productCount"
                      :options="numberOfItems"
                      id="orderItems">
                    </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="form-section" v-else>
              <div class="two-col-form">
                <div class="form-group no-fields">
                  <p>
                    <strong>Product:</strong>
                    {{ product.name }}
                  </p>

                  <p>
                    <strong>Number of Items:</strong>
                    {{ dataSet.productCount }}
                  </p>
                </div>

                <div class="form-group no-fields">
                  <p>
                    <strong>Order Date / Time:</strong>
                    {{ dataSet.orderDateTime }}
                  </p>

                  <p>
                    <strong>Payment Type:</strong>
                    Credit Card (ending in {{ dataSet.creditCardNumber }})
                  </p>
                </div>
              </div>

              <div class="two-col-form">
                <div class="form-group">
                  <label for="seasonTicketNumber">Season Ticket Number</label>
                  <input type="text" id="seasonTicketNumber" v-model="dataSet.stNumber" />
                </div>
              </div>
            </div>

            <h2>
              Customer Information
              <label for="notifyCustomerByEmail" v-if="!dataRef">
                <input id="notifyCustomerByEmail" v-model="notifyCustomerByEmail" type="checkbox" />
                Notify Customer by Email
              </label>
            </h2>

            <div class="form-section">
              <div class="two-col-form">
                <div class="form-group">
                  <label for="customerFirstName">First Name<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="customerFirstName" v-model="dataSet.customerFirstName" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="customerLastName">Last Name<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="customerLastName" v-model="dataSet.customerLastName" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="emailAddress">Email Address<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="emailAddress" v-model="dataSet.emailAddress" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="phoneNumber">Phone Number<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="phoneNumber" v-model="dataSet.phoneNumber" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="address">Address<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="address" v-model="dataSet.address" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="city">City<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="city" v-model="dataSet.city" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group mb">
                  <label for="settingsCountry">Country<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="dataSet.country"
                      @input="resetRegion()"
                      :options="getCountryList"
                      label="name"
                      :reduce="country => country.name"
                      id="settingsCountry">
                    </v-select>
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group hidden-field" v-if="dataSet.country && regions && regions.length">
                  <label for="settingsRegion">Province / State<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select :reduce="region => region.name"
                      v-model="dataSet.stateProvince"
                      :options="selectedCountryRegions(dataSet.country)"
                      label="name"
                      value="name"
                      id="settingsRegion">
                    </v-select>
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="city">Postal / Zip Code<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="city" v-model="dataSet.zipPostal" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="form-section" v-if="!dataRef">
              <h2>
                Payment Information

                <label for="showPaymentForm">
                  <input id="showPaymentForm" v-model="showPaymentForm" type="checkbox" />
                  Require Payment for Order
                </label>
              </h2>

              <div class="two-col-form" v-if="showPaymentForm">
                <div class="form-group">
                  <label for="creditCardName">Cardholder Name<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="creditCardName" v-model="dataSet.creditCardName" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="creditCardNumber">Card Number<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" id="creditCardNumber" v-model="dataSet.creditCardNumber" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="creditCardExpiry">Expiry<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }" class="select-group">

                      <v-select
                        v-model="dataSet.creditCardExpiryMonth"
                        :options="getMonthList"
                        label="name"
                        :reduce="month => month.value"
                        id="creditCardExpiry">
                      </v-select>

                      <v-select
                        :reduce="year => year.toString()"
                        v-model="dataSet.creditCardExpiryYear"
                        :options="getExpiryYearList"
                        id="creditCardExpiry">
                      </v-select>

                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>

                <div class="form-group">
                  <label for="creditCardCvv">CVV<span class="req">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <input type="text" class="input-half" id="creditCardCvv" v-model="dataSet.creditCardCvv" />
                    <span class="invalid">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

      <div class="body-right one">
        <!-- Summary of Charges -->
        <div class="content-panel">
          <div class="panel-title">
            <h3>Summary of Charges</h3>
          </div>
          <div class="panel-body">
            <div class="charges-summary" v-if="product.id || dataSet.grossOrderAmount">
              <p v-if="dataRef"><strong>Order Status: {{dataSet.status}}</strong></p>
              <p><strong>{{product.name}}</strong></p>

              <p>{{ dataSet.productCount || 0 }} Products x ${{ dataSet.pricePerItem ? dataSet.pricePerItem : product.price }} Per Product</p>

              <div class="flex">
                <p>
                  <span>Subtotal:</span>
                  <span>${{beforeTaxes}}</span>
                </p>

                <p>
                  <span>Taxes:</span>
                  <span>${{taxes}}</span>
                </p>

                <p>
                  <span>Fees:</span>
                  <span>${{fees}}</span>
                </p>

                <p class="total">
                  <span>Total:</span>
                  <span>${{totalCost}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="content-panel">
          <div class="panel-title">
            <h3>Order Notes</h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="orderNotes">Add any notes specific to this order:</label>
              <textarea id="orderNotes" v-model="dataSet.orderNotes"></textarea>
            </div>
          </div>
        </div>

        <!-- Delete Order -->
        <div class="link-bar" v-if="dataRef">
          <button class="btn btn-text btn-delete" @click="deleteData()" title="Delete">
            <i class="fa-thin fa-trash"></i>
            Delete Order
          </button>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import PaymentApi from "../../services/admin/paymentApi";
import EmailApi from "../../services/admin/emailApi";
import { mapGetters } from 'vuex';

export default {
  name: 'ProductOrder',
  data() {
    return {
      emailApi: new EmailApi(),
      paymentApi: new PaymentApi(),
      dataRef: this.$route.params.ref,
      dataSet: {
        id: null,
        address: '',
        city: '',
        country: '',
        creditCardExpiry: '',
        creditCardExpiryMonth: '',
        creditCardExpiryYear: '',
        creditCardName: '',
        creditCardNumber: '',
        customerFirstName: '',
        customerLastName: '',
        customerStatus: '',
        discountType: '',
        discountValue: 0,
        emailAddress: '',
        productId: null,
        productCount: 1,
        totalOrderAmount: 0,
        orderDateTime: null,
        orderNotes: '',
        overridePrice: false,
        paid: false,
        paymentMethod: '',
        phoneNumber: '',
        stateProvince: '',
        status: '',
        pricePerItem: null,
        zipPostal: '',
        paymentResult: null,
        grossOrderAmount: null,
        serviceFee: null,
        taxes: null
      },
      regions: [],
      product: {},
      productsRemaining: null,
      productList: [],
      numberOfItems: [1, 2, 3, 4, 5, 6],
      newDate: new Date().toISOString(),
      loading: false,
      showPaymentForm: true,
      notifyCustomerByEmail: true,
      saving: false
    }
  },
  metaInfo() {
    let self = this;
    return {
      title: self.title
    }
  },

  computed: {
    ...mapGetters ({
      getCurrentPartner: 'getCurrentPartner'
    }),

    title: function() {
      let self = this;

      if(self.dataRef) {
        return 'Edit Order'
      } else {
        return 'Add an Order'
      }
    },

    beforeTaxes: function() {
      let self = this;

      if(self.dataRef) { return self.dataSet.grossOrderAmount }
      else {
          if(self.product.id) {
            return self.dataSet.productCount * self.product.price;
          } else { return 0 }
      }
    },

    taxes: function() {
      let self = this;

      if(self.dataRef) { return self.dataSet.taxes ? self.dataSet.taxes : 0; }
      else {
        if(self.product.id) {
          return self.dataSet.productCount * self.product.price * ((self.product.taxRatePercentage || 0) / 100);
        } else { return 0 }
      }
    },

    fees: function() {
      let self = this;
      if(self.dataRef) { return self.dataSet.fees ? self.dataSet.fees : 0; }
      else {
        if(self.product.id) {
          return self.product.applyServiceFeePerItem ? self.dataSet.productCount * (self.product.serviceFee || 0) : (self.product.serviceFee || 0);
        } else { return 0 }
      }
    },

    totalCost: function() {
      let self = this;
      let totalCost = 0;

      if (self.dataRef) {
        totalCost += self.dataSet.productCount * self.dataSet.pricePerItem;
      } else {
        totalCost = (self.fees + self.taxes + self.beforeTaxes);
      }
      return totalCost;
    },

    ...mapGetters ([
      'db',
      'getCountryList',
      'getMonthList',
      'getExpiryYearList'
    ])
  },

  components: {

  },

  async mounted() {
    let self = this;

    if(self.dataRef) {
      await self.getOrder();
      await self.getProduct();
      self.selectedCountryRegions(self.dataSet.country);
    } else {
      self.dataSet.productCount = 1;
    }

    await self.getProductList();
  },

  methods: {
    notifyCustomer: async function (partnerId, orderId) {
      let self = this;
      await self.emailApi.sendProductOrderEmail(partnerId, orderId);

      self.$notify({
        group: 'saved',
        title: 'Customer Successfully Notified',
        type: 'success'
      });
    },
    getOrder: async function() {
      let self = this;
      self.loading = true;

      let ordersData = await self.db.collection('productOrders').doc(self.dataRef).get();
      self.dataSet = ordersData.data();

      self.loading = false;
    },

    getProduct: async function() {
      let self = this;

      let productData = await self.db.collection('products').doc(self.dataSet.productId).get();

      self.product = productData.data();
    },

    getProductList: async function() {
      let self = this;
      let dataSet = await self.db.collection('products').get();
      dataSet = dataSet.docs.map(doc => doc.data());

      self.productList = dataSet;
    },

    selectProduct: function(value) {
      let self = this;
      let product = self.productList.find(product => product.id === value);
      self.product = product;
    },

    setupSavedOrder: async function(ref) {
      let self = this;
      self.dataRef = ref;

      await self.$router.push({ path: '/product-orders/' + ref });
      await self.getOrder();
      await self.getProduct();
      self.selectedCountryRegions(self.dataSet.country);
      await self.getProductList();
    },

    saveChanges: async function() {
      let self = this;

      let success = await self.$refs.ordersForm.validate();
      let orderId = JSON.stringify(Date.now());
      let ref = self.dataRef ? self.dataRef : orderId;

      if(success) {
        self.saving = true;
        if(self.dataRef) {
          try {
            await self.db.collection('productOrders').doc(ref).update(self.dataSet);
            self.saving = false;
            self.$notify({
              group: 'saved',
              title: 'Order Saved',
              type: 'success'
            });
          }
          catch (error) {
            self.saving = false;
            self.$notify({
              group: 'saved',
              title: 'Error Saving - ' + error,
              type: 'error'
            });
          }
        }
        else {
          try {
            let order = self.dataSet;
            order.id = ref;
            order.orderDateTime = self.newDate;
            order.discountType = self.dataSet.discountType || '';
            order.discountValue = self.dataSet.discountValue || 0;
            order.pricePerItem = self.product.price || 0;
            order.paymentMethod = self.showPaymentForm ? 'creditDebit' : '';
            order.serviceFee = self.fees || 0;
            order.taxes = self.taxes || 0;
            order.grossOrderAmount = self.beforeTaxes;
            order.totalOrderAmount = self.totalCost;
            order.orderNotes = order.orderNotes || '';

            let paymentResult = null;
            if (self.showPaymentForm) {
              order.creditCardExpiry = `${order.creditCardExpiryYear.slice(2)}${order.creditCardExpiryMonth}`;
              paymentResult = (await self.paymentApi.processPayment(
                  self.getCurrentPartner,
                  order.totalOrderAmount,
                  order.creditCardNumber,
                  order.creditCardExpiry,
                  `Customer: ${order.customerFirstName} ${order.customerLastName} - Product: ${order.productId} x ${order.productCount}`
              )).data;
              if (!paymentResult.success) {
                throw `Error Processing Payment: ${paymentResult.message}`;
              }
            }
            delete order.creditCardExpiryMonth;
            delete order.creditCardExpiryYear;

            order.creditCardNumber = order.creditCardNumber.substr(order.creditCardNumber.length - 4);


            order.status = self.showPaymentForm ? paymentResult && paymentResult.success ? 'Completed' : 'Failed' : 'Reserved';
            order.paid = self.showPaymentForm ? paymentResult.success : false;
            order.paymentResult = paymentResult;

            await self.db.collection('productOrders').doc(ref).set(order);

            if (self.notifyCustomerByEmail) {
              await self.emailApi.sendProductOrderEmail(self.getCurrentPartner, order.id);
            }

            self.$notify({
              group: 'saved',
              title: 'Order Successfully Created',
              type: 'success'
            });
            self.saving = false;

            self.setupSavedOrder(ref);
          }
          catch (error) {
            self.saving = false;
            self.$notify({
              group: 'saved',
              title: 'Error Creating Order - ' + error,
              type: 'error'
            });
          }
        }
      }
    },

    deleteData: function() {
      let self = this;
      self.$modal.show('dialog', {
        title: 'Delete Confirmation',
        text: 'Are you sure you want to delete this order?',
        buttons: [
          {
            title: 'Confirm',
            class: 'btn dialogConfirmBtn',
            default: true,
            handler: async () => {
              let confirmButton = document.querySelector('.dialogConfirmBtn');
              confirmButton.innerHTML = '<i class="fa-thin fa-spinner-third fa-spin"></i>';

              self.db.collection('productOrders').doc(self.dataRef).delete()
              .then(function() {
                  self.$notify({
                    group: 'saved',
                    title: 'Order Deleted',
                    type: 'success'
                  });
                  self.$modal.hide('dialog');
                  self.$router.push({ name: 'ProductOrders'} )
              })
              .catch(function(error) {
                  self.$notify({
                    group: 'saved',
                    title: 'Error Deleting - ' + ref,
                    type: 'error'
                  });
              });
            }
          },
          {
            title: 'Cancel',
            class: 'btn btn-sec',
            handler: () =>  { self.$modal.hide('dialog'); }
          }
        ]
      });
    },

    selectedCountryRegions: function (name) {
      let self = this;

      var country = self.getCountryList.find((country) => {
        if(name) {
          if (country.name.toUpperCase() === name.toUpperCase()) {
            return country;
          }
        }
      });

      if (country && country.regions) {
        self.regions = country.regions;
        return country.regions;
      }
      if (country && country.regionNames) {
        self.regions = country.regionNames;
        return country.regionNames;
      }
      return null;
    },

    resetRegion: function() {
      let self = this;
      self.regions = [];
      self.dataSet.stateProvince = '';
      self.selectedCountryRegions(self.dataSet.country);
    }
  },

  watch: {
    'db': function() {
      let self = this;
      self.$router.push({ name: 'Orders' });
      
    },

  }
}
</script>
